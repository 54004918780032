.quill  {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: none;
    outline: none;
}

.ql-toolbar.ql-snow {
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #dfe2e6;
}

.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  overflow: visible;
}

.ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 13px;
  color: #6c7b89;
}

.ql-editor:focus {
  background-color: #4183D712;
}

.ql-container.ql-bubble:not(.ql-disabled) a::before {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
  background: #fff;
  border-radius: 2px;
  white-space: normal;
  z-index: 2;
}

.ql-container.ql-bubble:not(.ql-disabled) a::before {
  color: #4183d7;
  white-space: nowrap;
}

.ql-container.ql-bubble:not(.ql-disabled) a::after {
  border-top: none;
}

.ql-bubble .ql-tooltip {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
  background: #fff;
  border-radius: 2px;
  white-space: normal;
  z-index: 2;
}

.ql-bubble .ql-stroke {
  stroke: rgb(108, 123, 137);
}

.ql-bubble .ql-toolbar button:hover .ql-stroke {
  stroke: rgb(108, 123, 137);
}
.ql-bubble .ql-toolbar button.ql-active .ql-stroke {
  stroke: #000;
}

.ql-bubble .ql-fill, .ql-bubble .ql-stroke.ql-fill {
  fill: rgb(108, 123, 137);
}

.ql-bubble .ql-toolbar button:hover .ql-fill {
  fill: rgb(108, 123, 137);
}

.ql-bubble .ql-toolbar button.ql-active .ql-fill {
  fill: #000;
}

.ql-bubble .ql-toolbar button {
  background-color: transparent;
  transition: all .3s;
}
.ql-bubble .ql-toolbar button:hover {
  background-color: rgba(65, 131, 215, 0.07);
}

.ql-bubble .ql-tooltip-editor input[type=text] {
  color: #22313f;
}
.ql-container.ql-bubble:not(.ql-disabled) a {
  color: #4183d7;
}
.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  border: none;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border: none;
}

.mention {
  line-height: 24px;
}

.ql-mention-list-item {
  display: flex;
  flex-direction: column;
  height: 56px;
  padding: 12px 16px;
}

.ql-mention-list-item.selected {
  background: rgba(65, 131, 215, 0.07);
}

.ql-mention-list-item :nth-child(1) {
  display: block;
  color: rgb(34, 49, 63);
  font-family: Roboto-Regular;
  font-size: 13px;
  line-height: 16px;
  height: 16px;
}

.ql-mention-list-item :nth-child(2) {
  display: block;
  color: rgb(108, 123, 137);
  font-family: Roboto-Regular;
  font-size: 11px;
  line-height: 16px;
  height: 16px;
}
